import React from 'react';
import autoBind from 'react-autobind';
import { Form, Button, Layout, PageHeader, Table, Typography, Alert, Row, Col, Divider } from 'antd';
import { isMobile } from 'react-device-detect';
//
import CustomComponent from '../../components/CustomComponent';
//
import WhiteBox from '../commonComponents/WhiteBox';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
//
import Globals from '../../config/Globals';
import Utils from '../../components/Utils';

export default class CommonProductOrdersDetailsView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      id: this.props.match.params.id,
      orderID: this.props.match.params.id2,
      order: [],
      isLoading: false,
      sortedInfo: null,
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.fetchData();
  }

  async fetchData() {
    this.startLoading();
    const { id, orderID } = this.state;
    const resp = await this._getProductOrderExpanded(id, orderID);
    if (resp) this.setState({ ...resp, isLoading: false });
    else this.stopLoading();
  }

  render() {
    return (
      <Layout.Content className="pageContent">
        <CommonLoadingView isLoading={this.state.isLoading} />
        <PageHeader
          className="pageHeader"
          title="Product Orders Details"
          onBack={() => this.props.app.urlManager.pushBack()}
        />
        <WhiteBox>
          {this._renderOrderID()}
          {this._renderOrderForm()}
          {this._renderOrderTable()}
        </WhiteBox>
      </Layout.Content>
    );
  }

  /* private */
  _renderOrderID() {
    if (!this.state.orderID) {
      return null;
    }
    return (
      <Alert
        type="info"
        showIcon
        style={{ margin: '8px 0 24px' }}
        message={
          <>
            Product Order ID: <strong>{this.state.orderID}</strong>
          </>
        }
      />
    );
  }

  _renderOrderForm() {
    return (
      <>
        <Divider orientation="left">Product Order Information</Divider>
        <Row>
          <Col span={isMobile ? 24 : 6} offset={isMobile ? 0 : 1}>
            <Form.Item label="Purchaser"> {this.state.order?.name}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 5}>
            <Form.Item label="Status">{this.state.order?.status}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 7} offset={isMobile ? 0 : 1}>
            <Form.Item label="Order Date">{Utils.getDateOnUIFormatByTimestamp(this.state.order?.orderDate)}</Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="Transaction ID">{this.state.order?.providerTransactionID}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="Voucher">{this.state.order?.voucherID}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="Shipping Date">
              {Utils.getDateOnUIFormatByTimestamp(this.state.order?.shippingDate)}
            </Form.Item>
          </Col>
        </Row>
        {this.state.order?.refundedAmount?.length > 0 && (
          <Row>
            <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
              <Form.Item label="Refund amount">{this.state.order?.refundedAmount}</Form.Item>
            </Col>
            <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
              <Form.Item label="Refunded On">{this.state.order?.refundedOn}</Form.Item>
            </Col>
            <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
              <Form.Item label="Reason">{this.state.order?.cancelReason}</Form.Item>
            </Col>
          </Row>
        )}
        <Divider orientation="left">Shipping Information</Divider>
        <Row>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="Street Address">{this.state.order?.shippingInfo?.street0}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="Street Address 2">{this.state.order?.shippingInfo?.street1}</Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="City">{this.state.order?.shippingInfo?.city}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="Province">{this.state.order?.shippingInfo?.province}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="Country">{this.state.order?.shippingInfo?.country}</Form.Item>
          </Col>
          <Col span={isMobile ? 24 : 5} offset={isMobile ? 0 : 1}>
            <Form.Item label="Postal Code">{this.state.order?.shippingInfo?.postalCode}</Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  //Table support
  handleFilterChange(pagination, filters, sorter) {
    this.setState({ sortedInfo: sorter });
  }

  _renderOrderTable() {
    let { sortedInfo } = this.state;
    sortedInfo = sortedInfo || {};
    const columns = [
      {
        title: 'Product',
        key: 'description',
        dataIndex: 'description',
        width: '20%',
        sorter: (a, b) => a.description.localeCompare(b.description),
        sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order,
      },
      {
        title: 'Price',
        key: 'value',
        dataIndex: 'value',
        width: '10%',
        sorter: (a, b) => a.value.localeCompare(b.value),
        sortOrder: sortedInfo.columnKey === 'value' && sortedInfo.order,
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
        width: '10%',
        sorter: (a, b) => a.quantity.localeCompare(b.quantity),
        sortOrder: sortedInfo.columnKey === 'quantity' && sortedInfo.order,
      },
      {
        title: 'Shipping',
        key: 'shippingChargesValue',
        dataIndex: 'shippingChargesValue',
        width: '10%',
        sorter: (a, b) => a.shippingChargesValue.localeCompare(b.shippingChargesValue),
        sortOrder: sortedInfo.columnKey === 'shippingChargesValue' && sortedInfo.order,
        render: (shippingChargesValue) => `$${Utils.toCurrencyFormat(shippingChargesValue)}`,
      },
      {
        title: 'Subtotal',
        key: 'subtotal',
        dataIndex: 'subtotal',
        width: '10%',
        sorter: (a, b) => a.subtotal.localeCompare(b.subtotal),
        sortOrder: sortedInfo.columnKey === 'subtotal' && sortedInfo.order,
        render: (text, record) => `$${Utils.toCurrencyFormat(record.value + record.shippingChargesValue)}`,
      },
      {
        title: 'Taxes',
        key: 'itemTotalTax',
        dataIndex: 'itemTotalTax',
        width: '10%',
        sorter: (a, b) => a.itemTotalTax.localeCompare(b.itemTotalTax),
        sortOrder: sortedInfo.columnKey === 'itemTotalTax' && sortedInfo.order,
        render: (itemTotalTax) => `$${Utils.toCurrencyFormat(itemTotalTax)}`,
      },
      {
        title: 'Total',
        key: 'itemTotalValue',
        dataIndex: 'itemTotalValue',
        width: '10%',
        sorter: (a, b) => a.itemTotalValue.localeCompare(b.itemTotalValue),
        sortOrder: sortedInfo.columnKey === 'itemTotalValue' && sortedInfo.order,
        render: (itemTotalValue) => `$${Utils.toCurrencyFormat(itemTotalValue)}`,
      },
    ];

    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      onChange: this.handleFilterChange,
      locale: { emptyText: 'No order found!' },
      pagination: {
        pageSize: Globals.Table_PagingItemsPerPage,
        hideOnSinglePage: true,
        showSizeChanger: false,
        position: ['bottomCenter'],
      },
    };
    return (
      <Layout.Content>
        <Table columns={columns} dataSource={this.state.order.items} {...props} />
      </Layout.Content>
    );
  }

  //API
  async _getProductOrderExpanded(id, orderID) {
    const resp = await this.props.app.license.productOrder.getProductOrderExpanded(id, orderID);
    if (resp.statusCode == 200) {
      return { order: resp.body.productOrder };
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
}
