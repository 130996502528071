import React from 'react';
import autoBind from 'react-autobind';
import { Button, Tooltip, Row, Form } from 'antd';
import { SwapOutlined, CloseOutlined } from '@ant-design/icons';
//
import Utils from '../../../components/Utils';
//
import CommonOrganizationSelectionModal from './CommonOrganizationSelectionModal';
//
import '../../../assets/stylesheets/CommonOrganizationSelectionInput.less';
//props are: input { id, label, required, placeholder }, isEdit, isAdmin, selectedOrg, app, onSelection, allowClear
export default class CommonOrganizationSelectionInput extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  //Actions
  handleOrgSelected(orgObj) {
    if (this.props.onSelection) this.props.onSelection(orgObj);
  }
  handleChangeSelection() {
    if (this.selectionModal) this.selectionModal.show();
  }
  handleClearSelection() {
    if (this.props.onSelection) this.props.onSelection(null);
  }
  //UI
  render() {
    return (
      <>
        <CommonOrganizationSelectionModal
          app={this.props.app}
          requiresWorksafeValidation={!this.props.isAdmin}
          allowEmptySelection={!this.props.input.required}
          allowOrgsListing={this.props.isAdmin}
          {...Utils.propagateRef(this, 'selectionModal')}
          onSelection={this.handleOrgSelected}
        />
        <Form.Item
          required={this.props.input?.required}
          label={this.props.input?.label || 'Company'}
          name="orgID"
          id={this.props.input?.id || 'orgID'}
          rules={[
            {
              required: this.props.input?.required,
              validator: (a, v, c) =>
                this.props.input?.required && !this.props.selectedOrg ? c('Please, select a company!') : c(),
            },
          ]}
        >
          <Row className="employerInputContainer" type="flex" align="start" justify="middle">
            {this.props.selectedOrg?.name || this.props.input.placeholder || 'Unknown Employer'}
            {this.props.isEdit && (
              <Tooltip title="Select company">
                <Button
                  className="swapButton"
                  size="small"
                  style={{ margin: '4px' }}
                  icon={<SwapOutlined />}
                  shape="circle"
                  type="dashed"
                  onClick={this.handleChangeSelection}
                />
              </Tooltip>
            )}
            {this.props.allowClear && this.props.selectedOrg && (
              <Tooltip title="Clear selection">
                <Button
                  size="small"
                  style={{ margin: '4px' }}
                  icon={<CloseOutlined />}
                  shape="circle"
                  type="dashed"
                  onClick={this.handleClearSelection}
                />
              </Tooltip>
            )}
          </Row>
        </Form.Item>
      </>
    );
  }
}
