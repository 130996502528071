import React from 'react';
import { DatePicker, Button } from 'antd';

import '../assets/stylesheets/DateRangeFilter.less';

import CustomComponent from './CustomComponent';

export default class DateRangeFilter extends CustomComponent {
  state = {
    dates: [],
  };

  handleDateChange = (dates) => {
    this.setState({ dates });
  };

  handleFilter = () => {
    this.props.onFilter(this.state.dates);
  };

  render() {
    return (
      <div className="dateRangeFilterContainer">
        <span className="label">Date:</span>
        <DatePicker.RangePicker onChange={this.handleDateChange} size="large" />
        <Button
          type="primary"
          size="large"
          style={{ marginLeft: 4 }}
          onClick={this.handleFilter}
          disabled={this.props.buttonDisabled}
          loading={this.props.buttonLoading}
        >
          {this.props.buttonLabel || 'Filter'}
        </Button>
      </div>
    );
  }
}
