import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Drawer, Form, Tooltip, Popconfirm, Divider, Button, Table, message } from 'antd';
//
import Utils from '../../../components/Utils';
import CustomComponent from '../../../components/CustomComponent';
import DateRangeFilter from '../../../components/DateRangeFilter';
import CommonLoadingView from '../CommonLoadingView';
import Globals from '../../../config/Globals';
//
//props are: onMove, app, session
export default class CommonSessionMoveOperationDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sessions: [],
      userID: null,
      userFullName: null,
      newSessionID: null,
      selectedSession: false,
      isLoading: false,
      isVisible: false,
    };
  }
  //Public
  async show(userID) {
    const user = await this._fetchUserName([userID]);
    this.setState({
      sessions: [],
      selectedSession: false,
      userID: userID,
      userFullName: user[0]._source.fullName,
      isVisible: true,
    });
  }
  //Private actions
  async _fetchUserName(usersID) {
    const resp = await this.props.app.api.user.searchUsersByIDs(usersID);
    if (resp.statusCode === 200 && resp.body) {
      return resp.body.users;
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
  handleClose() {
    this.setState({ isVisible: false, isLoading: false });
  }
  async handleFilter(dates) {
    let [from, to] = dates || [];
    if (!from || !to) {
      this.setState({ sessions: [] });
      return;
    }
    to = to.toDate().getTime();
    from = from.toDate().getTime();
    this.setState({ sessions: [], isLoading: true });

    const resp = await this.props.app.classroom.session.getSessionList(from, to, this.props.session.courseID);

    if (resp.statusCode === 200 && resp.body && resp.body.sessions) {
      const sessionIDs = resp.body.sessions.filter((s) => s.id !== this.props.session.id).map((s) => s.id);

      // Expand sessions
      const expandedSessions = await Promise.all(
        sessionIDs.map(async (id) => {
          const session = await this.props.app.classroom.session.getSession(id, true);
          return session.body;
        })
      );

      // Collect unique instructor IDs
      const instructorIDs = expandedSessions.flatMap((session) =>
        (session.instructors || []).map((instructor) => instructor.userID)
      );
      const uniqueInstructorIDs = [...new Set(instructorIDs)];

      // Fetch instructor details using idm
      const idmResp = await this.props.app.idm.api.user.searchUsersByIDs(uniqueInstructorIDs);

      let instructors = [];
      if (idmResp.statusCode === 200 && idmResp.body && idmResp.body.users) {
        instructors = idmResp.body.users.map((user) => ({
          id: user._source.id,
          fullName: `${user._source.firstName} ${user._source.lastName}`,
        }));
      }

      // Map sessions with instructor names
      const sessionsWithInstructors = expandedSessions.map((session) => {
        const sessionInstructors = (session.instructors || []).map(
          (instructor) =>
            instructors.find((instructorData) => instructorData.id === instructor.userID)?.fullName || '-'
        );
        return {
          ...session,
          instructors: sessionInstructors,
        };
      });

      this.setState({ sessions: sessionsWithInstructors, isLoading: false });
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
      this.setState({ sessions: [], isLoading: false });
    }
  }
  async handleSubmit() {
    this.startLoading();
    const resp = await this.props.app.classroom.session.moveStudentSession(
      this.props.session.id,
      this.state.userID,
      this.state.newSessionID
    );
    if (resp.statusCode === 200 && resp.body) {
      message.success('Student successfully moved!');
      this.handleClose();
      if (this.props.onMove) this.props.onMove();
    } else {
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
    this.stopLoading(true);
  }

  _getCityAndVenueNames(session) {
    const venue = this.props.app.sharedCache().getVenueByID(session?.venueID);
    const city = this.props.app.sharedCache().getCityByID(venue?.cityID);
    const cityName = city ? `${city.name}: ` : '';
    return `${cityName}${venue?.name || '-'}`;
  }

  //UI
  render() {
    return (
      <Drawer
        open={this.state.isVisible}
        title={`Select a session to move => Student: ${this.state.userFullName}`}
        placement="right"
        onClose={this.handleClose}
        width={900}
      >
        <DateRangeFilter onFilter={this.handleFilter} />
        <CommonLoadingView isLoading={this.state.isLoading} isFixed />
        {this.state.sessions.length > 0 && (
          <>
            <Divider>Sessions</Divider>
            {this._renderSessionsMoveOperationTable()}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip placement="bottomLeft" title="Move to selected session">
                <Popconfirm
                  placement="top"
                  title={`This will move this student from the current session into the newly selected one. Confirm operation?`}
                  onConfirm={this.handleSubmit.bind(this)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="primary" disabled={!this.state.selectedSession} style={{ margin: '15px 0' }}>
                    {' '}
                    Move{' '}
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
          </>
        )}
      </Drawer>
    );
  }

  /* private UI */
  _renderSessionsMoveOperationTable() {
    const courses = this.props.app.sharedCache().getAllUniqueCourses();

    const columns = [
      { title: 'Internal name', key: 'internalName', dataIndex: 'internalName', width: '20%' },
      {
        title: 'Course',
        key: 'courseID',
        width: '20%',
        render: (props) => courses.find((c) => c.id === props.courseID)?.description || '',
      },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        width: '20%',
        render: (props) => Globals.getTemplateTypeIcon(props, true),
      },
      {
        title: 'Instructors',
        key: 'instructors',
        dataIndex: 'instructors',
        width: '20%',
        render: (instructors) => (instructors && instructors.length > 0 ? instructors.join(', ') : '-'),
      },
      {
        title: 'Seats',
        key: 'seats',
        width: '15%',
        render: (text, session) => {
          let publicCapacity =
            (session.capacity || 0) - (session.reservedCapacity || 0) - (session.enrolmentCount || 0);
          if (!session.capacity || session.capacity < 0) {
            return 'Unlimited';
          }
          publicCapacity = Math.max(publicCapacity, 0);
          return `${session.enrolmentCount || 0} / ${publicCapacity}`;
        },
      },
      {
        title: 'Venue',
        key: 'venue',
        dataIndex: 'venue',
        width: '20%',
        render: (text, record) => this._getCityAndVenueNames(record),
      },
      {
        title: 'Dates',
        key: 'dates',
        width: '20%',
        render: (props) => {
          if (props.startDate && props.startDate.length > 0)
            return props.startDate.map((s) => Utils.getDateAndTimeOnUIFormatByTimestamp(s)).join(', ');
          else return '-';
        },
      },
    ];

    const props = {
      rowKey: 'id',
      loading: this.state.isLoading,
      locale: { emptyText: 'No session found!' },
    };
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ newSessionID: selectedRowKeys[0] });
        this.setState({ selectedSession: true });
      },
    };
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Layout.Content>
          <Table
            columns={columns}
            dataSource={this.state.sessions || []}
            {...props}
            pagination={false}
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
          />
        </Layout.Content>
      </Form>
    );
  }
}
