import React, { Component } from 'react';
import { message, notification, Modal } from 'antd';
import autoBind from 'react-autobind';
import LogRocket from 'logrocket';
import { ExclamationCircleOutlined } from '@ant-design/icons';
//
class AlertController extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }
  // Actions
  showAPIErrorAlert(title, resp, resp2) {
    console.error(resp, resp2);
    let errMessage = null;
    if (resp && resp.body && resp.body.err) errMessage = resp.body.err;
    //40x codes
    else if (resp && resp.body && resp.body.message) errMessage = resp.body.message;
    //20x codes with error (auth)
    else if (resp && resp.error && resp.error.message) errMessage = resp.error.message;
    //2nd error
    else if (resp2 && resp2.body && resp2.body.err) errMessage = resp2.body.err;
    else if (resp2 && resp2.body && resp2.body.message) errMessage = resp2.body.message;
    else if (resp2 && resp2.error && resp2.error.message) errMessage = resp2.error.message;
    //
    if (!errMessage)
      errMessage = 'Unknown error.\nTry reloading the page, if the error persists, please, contact support!';
    notification.error({ message: !title ? 'Error' : title, description: errMessage, duration: 0 });
    //Log rocket
    LogRocket.captureMessage(`[${title || 'Error'}] ${errMessage}`, {
      tags: { type: 'API Error Message' },
      extra: {
        title,
        description: errMessage,
        error1: JSON.stringify(resp),
        ...(resp2 ? { error2: JSON.stringify(resp2) } : {}),
      },
    });
  }
  showErrorAlert(title, description) {
    notification.error({ message: title, description, duration: 0 });
    //Log rocket
    LogRocket.captureMessage(`[${title}] ${description}`, {
      tags: { type: 'Error Message' },
      extra: { title, description },
    });
  }
  showSuccessAlert(title, description) {
    message.success(title + description, 5);
  }
  showWarningAlert(title, description) {
    notification.warning({ message: title, description: description });
  }
  showQuestionAlert(title, description, { okText = 'OK', cancelText = 'Cancel' } = {}) {
    return new Promise((resolve) => {
      Modal.confirm({
        title,
        icon: <ExclamationCircleOutlined />,
        content: description,
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
        className: 'confirmation-modal',
        okText,
        cancelText,
      });
    });
  }
  showPromptAlert(title, description) {
    return window.prompt(`${title}\n${description}`);
  }

  // UI
  render() {
    return <></>;
  }
}
export default AlertController;
