import moment from 'moment-timezone';
import Globals from '../config/Globals';
//
export default class UtilsSession {}

/* sessoes */
UtilsSession.isOnSessionPeriods = function (sessionObj) {
  if (sessionObj.startDate && sessionObj.startDate.length > 0) {
    for (const index in sessionObj.startDate) {
      const startDate = (sessionObj.startDate || [])[index];
      const endDate = (sessionObj.endDate || [])[index];
      if (!startDate || !endDate) continue; //skip interation to later deny

      if (Date.now() >= startDate && Date.now() <= endDate) return true; //accept
    }
    return false; //not accepted on session that has start date
  }
  return true; //no start date means always on access period
};

UtilsSession.getTodaysClosestSession = function (launchSchedule, timezone) {
  const today = moment().tz(timezone).isoWeekday();
  const sortedLaunchSchedule = [...launchSchedule].sort((a, b) => a.dayOfWeek - b.dayOfWeek);

  const todaysSession = sortedLaunchSchedule.find((schedule) => schedule.dayOfWeek == today);

  if (todaysSession) {
    const [endHours, endMinutes] = todaysSession.endTime.split(':');

    const endTime = moment().tz(timezone).hours(endHours).minutes(endMinutes) * 1000;
    const now = moment().tz(timezone);

    if (now.isBefore(endTime)) {
      return {
        isToday: true,
        session: todaysSession,
      };
    }
  }

  let nextSession;

  // Find for a session in the future
  nextSession = sortedLaunchSchedule.find((schedule) => schedule.dayOfWeek > today);

  if (!nextSession) {
    // Find for a session in the past if there are no future sessions
    nextSession = sortedLaunchSchedule.find((schedule) => schedule.dayOfWeek < today);
  }
  return {
    isToday: false,
    session: nextSession,
  };
};

UtilsSession.isOnLaunchSchedule = function (launchSchedule, timezone) {
  if (!launchSchedule?.length) return false;

  const { isToday, session } = UtilsSession.getTodaysClosestSession(launchSchedule, timezone);

  if (!isToday) {
    return false;
  }

  // deals with scenarios where session could not be determined
  // crash reported in production when looking at a scheduled session with recurring dates
  if (!session) {
    return false;
  }

  const [startHours, startMinutes] = session.startTime.split(':');
  const [endHours, endMinutes] = session.endTime.split(':');
  const startTime = moment().tz(timezone).hours(startHours).minutes(startMinutes);
  const endTime = moment().tz(timezone).hours(endHours).minutes(endMinutes);
  const now = moment().tz(timezone);

  const isAvailable = now.isAfter(startTime) && now.isBefore(endTime);

  return isAvailable;
};

UtilsSession.canEnrolmentBeCanceled = function (session, cancelPolicy, isAdmin) {
  //if (isAdmin) return false; //TODO: REMOVE THIS OVERRIDE IN THE FUTURE
  const acceptedSessionTypes = [
    Globals.Session_Type.SCHEDULED_ONLINE_EXT,
    Globals.Session_Type.SCHEDULED_PRESENTIAL,
    Globals.Session_Type.SCHEDULED_PRESENTIAL,
    Globals.Session_Type.SCHEDULED_VILT,
    Globals.Session_Type.SCHEDULED_WEBINAR,
  ];
  if (
    (cancelPolicy &&
      cancelPolicy?.cancelPolicy?.canBeCanceled &&
      acceptedSessionTypes.includes(session?.type) &&
      Math.min(...session.startDate) >= Date.now()) ||
    isAdmin
  )
    return true;
  return false;
};

UtilsSession.convertMinutesToHoursAndMinutes = function (minutes) {
  if (typeof minutes !== 'number' || isNaN(minutes)) {
    throw new Error('Input must be a number');
  }

  const hours = Math.floor(minutes / 60);
  const finalMinutes = minutes % 60;

  return { hours, minutes: finalMinutes };
};
