import React from 'react';
import autoBind from 'react-autobind';
import { ChromePicker } from 'react-color';
import { Select, Form, Divider, Row, Button, Radio, Col, Input, InputNumber, Popover, Switch, Dropdown } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import MarkdownEditor from '@ikonintegration/react-rte';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';
//
import CommonOrganizationSelectionModal from '../OrganizationSelection/CommonOrganizationSelectionModal';
import CommonOrgReferralTable from '../CommonOrgReferralTable';
//
import CommonPopoversContent from './_CommonPopoversContent';
import { isThisSecond } from 'date-fns';
//props: app, isEdit, courses, venues
export default class CommonTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      type: Globals.Session_Type.SCHEDULED_ONLINE_EXT,
      resources: MarkdownEditor.createEmptyValue(),
      specialInstructions: MarkdownEditor.createEmptyValue(),
      overview: MarkdownEditor.createEmptyValue(),
      possibleReferrals: [],
      referralOrgs: null,
      productsForPurchase: [],
      askForReferrals: false,
      isChargeForMaterialsEnable: false,
      hasMaterialNone: true,
    };
  }
  //Public
  resetFields(type) {
    this.setState({ type: type || Globals.Session_Type.SCHEDULED_ONLINE_EXT });
    this.form.resetFields();
  }
  setFieldsValue(data) {
    let onlineAccessPeriodObject = this._getOnlineAccessPeriodObject(data.onlineAccessPeriod);
    const sections = data?.content?.sections?.[0]?.content;
    const overview = data?.overview;
    const specialInstructions = data?.specialInstructions;
    this.setState(
      {
        type: data.type,
        askForReferrals: !!data?.askForReferrals,
        possibleReferrals: data?.possibleReferrals || [],
        productsForPurchase: data?.productsForPurchase || [],
        resources: sections
          ? MarkdownEditor.createValueFromString(sections, 'markdown')
          : MarkdownEditor.createEmptyValue(),
        specialInstructions: specialInstructions
          ? MarkdownEditor.createValueFromString(specialInstructions, 'markdown')
          : MarkdownEditor.createEmptyValue(),
        overview: overview
          ? MarkdownEditor.createValueFromString(overview, 'markdown')
          : MarkdownEditor.createEmptyValue(),
        isChargeForMaterialsEnable: data?.chargeForMaterials,
        calendarColor: data?.calendarColor,
        hasMaterialNone: data?.hasMaterial === Globals.Session_HasMaterial.NONE,
      },
      () => {
        this.form.setFieldsValue({
          ...data,
          ...data.notificationDetails,
          adminEmails: data.notificationDetails?.adminEmails?.join(', ') || '',
          ...onlineAccessPeriodObject,
        });
        this._loadReferralNames();
      }
    );
  }
  async validateFields() {
    if (!this.form) return false;
    const formData = await this.form.validateFields();
    if (!formData) return false;
    const onlineAccessPeriod = this._calculateOnlineAccessPeriod(
      formData.onlineAccessPeriod,
      formData.onlineAccessPeriodUnity
    );
    delete formData.onlineAccessPeriodUnity;
    delete formData.resources;
    return {
      ...formData,
      adminEmails: undefined,
      shouldNotifyEnrolmentToAdmins: undefined,
      notifyWhenSessionFull: undefined,
      onlineAccessPeriod,
      overview: this.state.overview.toString('markdown'),
      possibleReferrals: this.state.possibleReferrals || [],
      productsForPurchase: this.state.productsForPurchase || [],
      calendarColor: this.state.calendarColor,
      specialInstructions: this.state.specialInstructions.toString('markdown'),
      content: {
        sections: [
          {
            content: this.state.resources.toString('markdown'),
            enabled: true,
            name: 'Resources',
            order: 0,
          },
        ],
      },
      notificationDetails: {
        adminEmails:
          formData?.adminEmails?.length > 0 ? formData?.adminEmails?.replace(/[ ?&/]/g, '')?.split(',') || [] : [],
        shouldNotifyEnrolmentToAdmins: !!formData.shouldNotifyEnrolmentToAdmins,
        notifyWhenSessionFull: !!formData.notifyWhenSessionFull,
      },
    };
  }

  //Actions
  handleChangeAskForReferrals() {
    this.setState({ askForReferrals: !this.state.askForReferrals });
  }
  async handleTypeChange(type) {
    if (this.state.type != type.target.value) {
      const confirmation = await this.props.app.alertController.showQuestionAlert(
        'Attention!',
        `You are changing the template type and some fields might not be compatible with the previous one. All fields will be reset and you will loose any changes!\nAre you sure you want to proceed?`
      );
      if (!confirmation) {
        this.form.setFieldsValue({ type: this.state.type });
        return;
      }
    }
    this.setState({ type: type.target.value }, () => {
      const internalName = this.form.getFieldValue('internalName');
      const courseID = this.form.getFieldValue('courseID');
      const name = this.form.getFieldValue('name');

      this.resetFields(type.target.value);
      this.form.setFieldsValue({ internalName, courseID, name });
    });
  }
  //referral actions
  handleReferralSelected(org) {
    const refs = this.state.possibleReferrals || [];
    if (refs.includes(org.id)) return;
    this.setState({ referralOrgs: null, possibleReferrals: [...refs.concat([org.id])] }, this._loadReferralNames);
  }
  handleDeleteReferral(orgID) {
    const refs = this.state.possibleReferrals || [];
    refs.splice(refs.indexOf(orgID), 1);
    this.setState({ possibleReferrals: [...refs] });
  }
  handleAddReferral() {
    this.referralSelectionModal.show();
  }
  //material actions
  handleChangeHasMaterial(hasMaterial) {
    this.setState({ hasMaterialNone: hasMaterial === Globals.Session_HasMaterial.NONE });
  }
  handleChangeChargeForMaterials() {
    this.setState({
      chargeForMaterials: !this.state.chargeForMaterials,
      isChargeForMaterialsEnable: !this.state.isChargeForMaterialsEnable,
    });
  }

  //UI
  render() {
    return this._renderMainForm();
  }

  _calculateOnlineAccessPeriod(onlineAccessPeriod, onlineAccessPeriodUnity) {
    if (!onlineAccessPeriod) return undefined;
    onlineAccessPeriodUnity = onlineAccessPeriodUnity || 'days';

    if (onlineAccessPeriodUnity === 'days') return onlineAccessPeriod * 1440;
    if (onlineAccessPeriodUnity === 'hours') return onlineAccessPeriod * 60;
    return onlineAccessPeriod;
  }
  _getOnlineAccessPeriodObject(onlineAccessPeriod) {
    let onlineAccessPeriodObject = {};

    if (onlineAccessPeriod) {
      if (onlineAccessPeriod >= 1440) {
        onlineAccessPeriodObject.onlineAccessPeriod = onlineAccessPeriod / 1440;
        onlineAccessPeriodObject.onlineAccessPeriodUnity = 'days';
      } else if (onlineAccessPeriod >= 60) {
        onlineAccessPeriodObject.onlineAccessPeriod = onlineAccessPeriod / 60;
        onlineAccessPeriodObject.onlineAccessPeriodUnity = 'hours';
      } else {
        onlineAccessPeriodObject.onlineAccessPeriod = onlineAccessPeriod;
        onlineAccessPeriodObject.onlineAccessPeriodUnity = 'minutes';
      }
    }

    return onlineAccessPeriodObject;
  }
  /* Private UI */
  _renderMainForm() {
    const isSchedulable = !(
      this.state.type == Globals.Session_Type.ONLINE_EXT || this.state.type == Globals.Session_Type.ONLINE
    );
    const isOnlineExt =
      this.state.type == Globals.Session_Type.ONLINE_EXT ||
      this.state.type == Globals.Session_Type.SCHEDULED_ONLINE_EXT;
    const isVILT = this.state.type == Globals.Session_Type.SCHEDULED_VILT;
    const isWebinar = this.state.type == Globals.Session_Type.SCHEDULED_WEBINAR;
    const isPresential = this.state.type == Globals.Session_Type.SCHEDULED_PRESENTIAL;
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Row gutter={32}>
          <Col span={12}>
            <Form.Item
              label="Internal name"
              name="internalName"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Display name
                  <Popover title="Display name" content="This field will be displayed to users on their interface.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col span={7}>
            <Form.Item
              name="courseID"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Course
                  <Popover
                    title="Course"
                    content="What course the sessions created from this template will be related to."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select
                mode="single"
                showSearch
                disabled={this.props.isEdit}
                filterOption={(input, option) => {
                  const course = (this.props.courses || []).find((c) => option.value == c.id);
                  return `${course.description}`.toLowerCase().includes(input.toLowerCase());
                }}
              >
                {(this.props.courses || []).map((c) => {
                  return (
                    <Select.Option value={c.id} key={c.id}>
                      {c.description}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label={
                <>
                  Session Type
                  <Popover
                    title="Session Types"
                    content={CommonPopoversContent.renderSessionTypesPopoverContent()}
                    placement="right"
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
              name="type"
              initialValue={this.state.type}
            >
              <Radio.Group
                onChange={this.handleTypeChange}
                value={this.state.type}
                disabled={this.props.isEdit}
                optionType="button"
                buttonStyle="solid"
                options={[
                  {
                    value: Globals.Session_Type.SCHEDULED_ONLINE_EXT,
                    label: Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_ONLINE_EXT, true),
                  },
                  {
                    value: Globals.Session_Type.SCHEDULED_PRESENTIAL,
                    label: Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_PRESENTIAL, true),
                  },
                  {
                    value: Globals.Session_Type.SCHEDULED_VILT,
                    label: Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_VILT, true),
                  },
                  {
                    value: Globals.Session_Type.SCHEDULED_WEBINAR,
                    label: Globals.getTemplateTypeIcon(Globals.Session_Type.SCHEDULED_WEBINAR, true),
                  },
                  {
                    value: Globals.Session_Type.ONLINE_EXT,
                    label: Globals.getTemplateTypeIcon(Globals.Session_Type.ONLINE_EXT, true),
                  },
                  {
                    value: Globals.Session_Type.ONLINE,
                    label: Globals.getTemplateTypeIcon(Globals.Session_Type.ONLINE, true),
                    disabled: !this.props.app.isSysAdmin(),
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        {this._renderGeneralSubform(isSchedulable)}
        {this._renderMaterialsSubform()}
        {this._renderContentSubform()}
        {this.state.askForReferrals && this._renderReferralSubform()}
        {isSchedulable && this._renderSchedulableSubform(isPresential)}
        {(isOnlineExt || isWebinar || isVILT) && this._renderOnlineIntegrationSubform()}
        {this._renderNotificationsSubform()}
      </Form>
    );
  }
  /* Subforms */
  _renderGeneralSubform(isSchedulable) {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Details</Divider>

        <Row gutter={32}>
          <Col span={4}>
            <Form.Item
              name="capacity"
              label={
                <>
                  Capacity
                  <Popover title="Capacity" content={CommonPopoversContent.renderCapacityPopoverContent()}>
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ maxWidth: 140 }} min={-1} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="reservedCapacity"
              label={
                <>
                  Reserved Capacity
                  <Popover
                    title="Reserved Capacity"
                    content={CommonPopoversContent.renderReservedCapacityPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ maxWidth: 140 }} min={-1} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="minCapacity"
              label={
                <>
                  Minimum Capacity
                  <Popover
                    title="Minimum Capacity"
                    content={CommonPopoversContent.renderMinimumCapacityPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber disabled style={{ maxWidth: 140 }} min={-1} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="releaseType"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Release Type
                  <Popover title="Release Type" content={CommonPopoversContent.renderReleaseTypePopoverContent()}>
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch>
                <Select.Option
                  disabled={isSchedulable}
                  value={Globals.Session_ReleaseType.NONE}
                  key={Globals.Session_ReleaseType.NONE}
                >
                  None
                </Select.Option>
                <Select.Option
                  disabled={!isSchedulable}
                  value={Globals.Session_ReleaseType.AUTO}
                  key={Globals.Session_ReleaseType.AUTO}
                >
                  Auto
                </Select.Option>
                <Select.Option value={Globals.Session_ReleaseType.MANUAL} key={Globals.Session_ReleaseType.MANUAL}>
                  Manual
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="cancelationPolicyID"
              label={
                <>
                  Cancelation Policy
                  <Popover
                    title="Cancelation Policy"
                    content={CommonPopoversContent.renderCancelationPolicyPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select
                mode="single"
                showSearch
                filterOption={(input, option) => {
                  const cancelationPolicies = (this.props.app.sharedCache().getCancelationPolicies() || []).find(
                    (c) => option.value == c.id
                  );
                  return `${cancelationPolicies.description}`.toLowerCase().includes(input.toLowerCase());
                }}
              >
                <Select.Option value={''} key="empty-option" />

                {(this.props.app.sharedCache().getCancelationPolicies() || []).map((c) => {
                  return (
                    <Select.Option value={c.id} key={c.id}>
                      {c.description}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={4}>
            <Form.Item
              name="requiresInstructor"
              valuePropName="checked"
              label={
                <>
                  Requires Instructor
                  <Popover
                    title="Requires Instructor"
                    content={CommonPopoversContent.renderRequiresInstructorPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="requiresAdminReview"
              valuePropName="checked"
              label={
                <>
                  Requires Admin Review
                  <Popover
                    title="Requires Admin Review"
                    content={CommonPopoversContent.renderRequiresAdminReviewPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="askForReferrals"
              valuePropName="checked"
              label={
                <>
                  Ask for Affiliates?
                  <Popover
                    title="Ask for Affiliates?"
                    content={CommonPopoversContent.renderAskForAffiliatesPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch onChange={this.handleChangeAskForReferrals} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="isPrivate"
              valuePropName="checked"
              label={
                <>
                  Private Sessions
                  <Popover
                    title="Private Sessions"
                    content={CommonPopoversContent.renderSessionPrivatePopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="calendarColor"
              label={
                <>
                  Calendar Color
                  <Popover title="Calendar Color" content={CommonPopoversContent.renderCalendarColorPopoverContent()}>
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Dropdown
                trigger={['click']}
                dropdownRender={() => (
                  <div style={{ padding: '10px', zIndex: 9999 }}>
                    <ChromePicker
                      color={this.state.calendarColor}
                      disableAlpha={true}
                      onChangeComplete={(color) => {
                        this.setState({ calendarColor: color.hex });
                      }}
                    />
                  </div>
                )}
              >
                <Button style={{ background: this.state.calendarColor, marginLeft: 20 }}> </Button>
              </Dropdown>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={4}>
            <Form.Item
              name="chargeDeferred"
              valuePropName="checked"
              label={
                <>
                  Charge Deferred
                  <Popover title="Charge Deferred" content={CommonPopoversContent.renderChargeDeferredPopoverContent()}>
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="isEligibleForNoCost"
              valuePropName="checked"
              label={
                <>
                  Eligible for No Cost
                  <Popover
                    title="Eligible for No Cost"
                    content={CommonPopoversContent.renderIsEligibleForNoCostPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="confirmInfoOnEnrolment"
              valuePropName="checked"
              label={
                <>
                  Confirm info at enrolment
                  <Popover
                    title="Confirm info at enrolment"
                    content={CommonPopoversContent.renderConfirmInfoOnEnrolmentPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="checkGradeDelay"
              label={
                <>
                  Grade Check Delay
                  <Popover
                    title="Grade Check Delay"
                    content={CommonPopoversContent.renderGradeCheckDelayPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber style={{ maxWidth: 140 }} min={-1} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  _renderMaterialsSubform() {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Materials</Divider>

        <Row gutter={32}>
          <Col span={4}>
            <Form.Item
              name="hasMaterial"
              rules={[{ required: true, message: 'This field is required!' }]}
              initialValue={Globals.Session_HasMaterial.NONE}
              label={
                <>
                  Course Material
                  <Popover title="Course Material" content={CommonPopoversContent.renderHasMaterialPopoverContent()}>
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch onChange={this.handleChangeHasMaterial}>
                <Select.Option value={Globals.Session_HasMaterial.NONE} key={Globals.Session_HasMaterial.NONE}>
                  None
                </Select.Option>
                <Select.Option value={Globals.Session_HasMaterial.ALL} key={Globals.Session_HasMaterial.ALL}>
                  Send to Venue
                </Select.Option>
                <Select.Option
                  value={Globals.Session_HasMaterial.INDIVIDUAL}
                  key={Globals.Session_HasMaterial.INDIVIDUAL}
                >
                  Send to Student
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="chargeForMaterials"
              valuePropName="checked"
              label={
                <>
                  Charge for Materials
                  <Popover
                    title="Charge for Materials"
                    content={CommonPopoversContent.renderChangeForMaterialsPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch
                id="chargeForMaterials"
                onChange={this.handleChangeChargeForMaterials}
                disabled={this.state.hasMaterialNone}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="courseMaterialProductID"
              rules={[{ required: this.state.isChargeForMaterialsEnable, message: 'This field is required!' }]}
              label={
                <>
                  Course Material Product
                  <Popover
                    title="Course Material Product"
                    content={CommonPopoversContent.renderCourseMaterialProductPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select
                mode="single"
                showSearch
                disabled={!this.state.isChargeForMaterialsEnable || this.state.hasMaterialNone}
                filterOption={(input, option) => {
                  const product = (this.props.app.sharedCache().getProducts() || []).find((p) => option.value == p.id);
                  return `${product?.description}`.toLowerCase().includes(input.toLowerCase());
                }}
              >
                <Select.Option value="" key="none">
                  None
                </Select.Option>
                {(this.props.app.sharedCache().getProducts() || []).map((p) => {
                  return (
                    <Select.Option value={p.id} key={p.id}>
                      {p.description}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="productsForPurchase"
              label={
                <>
                  Products offered for Purchase
                  <Popover
                    title="Products offered for Purchase"
                    content="Products listed here will be offered for purchase in sessions created from this template if the feature is enabled on the session."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select
                mode="single"
                showSearch
                filterOption={(input, option) => {
                  const product = (this.props.app.sharedCache().getProducts() || []).find((p) => option.value === p.id);
                  return `${product?.description}`.toLowerCase().includes(input.toLowerCase());
                }}
                onChange={(value) => this.setState({ productsForPurchase: [value] })}
              >
                <Select.Option value="" key="none">
                  None
                </Select.Option>
                {(this.props.app.sharedCache().getProducts() || [])
                  .filter((p) => !p.hideInPurchaseDialog)
                  .map((p) => (
                    <Select.Option value={p.id} key={p.id}>
                      {p.description}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  _renderContentSubform() {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Content</Divider>

        <Row>
          <Col span={11}>
            <Form.Item label="Overview" name="overview" valuePropName="none">
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.overview}
                onChange={(overview) => this.setState({ overview })}
              />
            </Form.Item>
          </Col>
          <Col span={12} offset={1}>
            <Form.Item label="Resources" name="resources" valuePropName="none">
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.resources}
                onChange={(resources) => this.setState({ resources })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item label="Special Instructions" name="specialInstructions" valuePropName="none">
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.specialInstructions}
                onChange={(specialInstructions) => this.setState({ specialInstructions })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={11}>
            <Form.Item name="internalNotes" label={'Internal Notes'}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={11}>
            <Form.Item name="instructorNotes" label={'Instructor Notes'}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  _renderReferralSubform() {
    return (
      <>
        <CommonOrganizationSelectionModal
          {...Utils.propagateRef(this, 'referralSelectionModal')}
          app={this.props.app}
          allowOrgsListing
          onSelection={this.handleReferralSelected}
        />
        <Divider orientation="left">Referral Settings</Divider>
        <Row gutter={32}>
          <Col span={4}>
            <Form.Item
              name="isReferralPercentage"
              valuePropName="checked"
              label={
                <>
                  Is Referral Amount Percentage of the purchase value?
                  <Popover
                    title="Is Referral Amount Percentage of the purchase value?"
                    content={CommonPopoversContent.renderIsReferralPercentagePopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={3} offset={1}>
            <Form.Item
              name="referralAmount"
              label={'Referral Amount'}
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <InputNumber min={0} precision={2} />
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" style={{ marginTop: 10 }}>
          <Col span={20}>
            <Form.Item
              name="possibleReferrals"
              label={'List of Referrals'}
              rules={[
                {
                  validator: (r, v) => {
                    return new Promise((resolve, reject) => {
                      if (this.state.possibleReferrals?.length > 0) resolve();
                      else reject(`One referrals is required!`);
                    });
                  },
                },
              ]}
            >
              <CommonOrgReferralTable
                ids={this.state.possibleReferrals}
                onDelete={this.handleDeleteReferral}
                isLoading={this.state.isLoading}
                orgs={this.state.referralOrgs}
              />
            </Form.Item>
          </Col>
          <Col offset={1}>
            {' '}
            <Button type="primary" onClick={this.handleAddReferral}>
              Add Referral
            </Button>{' '}
          </Col>
        </Row>
      </>
    );
  }
  _renderSchedulableSubform(isPresential) {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Date & Time</Divider>
        <Row gutter={32}>
          <Col span={3}>
            <Form.Item
              name="duration"
              label={'Duration'}
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <InputNumber
                min={1}
                formatter={(value) => (value ? `${value} min`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '')}
                parser={(value) => value.replace(/\ min\s?|(,*)/g, '').replace(/\D+/g, '')}
              />
            </Form.Item>
          </Col>
          {isPresential && (
            <Col span={8}>
              <Form.Item
                name="venueID"
                label={'Venue'}
                rules={[{ required: true, message: 'This field is required!' }]}
              >
                <Select
                  mode="single"
                  showSearch
                  filterOption={(input, option) => {
                    const venue = (this.props.venues || []).find((venue) => option.value == venue.id);
                    let cityName = this.props.app.sharedCache().getCityByID(venue.cityID)?.name;
                    cityName = cityName ? `${cityName}: ` : '';
                    return `${cityName}${venue.name}`.toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  {(this.props.venues || []).map((venue) => {
                    return (
                      <Select.Option value={venue.id} key={venue.id}>
                        {(() => {
                          const cityName = this.props.app.sharedCache().getCityByID(venue.cityID)?.name;
                          return cityName ? `${cityName}: ` : '';
                        })()}
                        {venue.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}
          {isPresential && (
            <Col span={11}>
              <Form.Item
                label="Venue Complement"
                name="venueComplement"
                rules={[{ required: false, message: 'This field is required!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>
      </div>
    );
  }
  _renderOnlineIntegrationSubform() {
    const allowedOnlineCourseProviders =
      this.props.app.sharedCache().getTenantConfig().customer?.allowedOnlineCourseProviders || [];
    const isVILT = this.state.type == Globals.Session_Type.SCHEDULED_VILT;
    const isWebinar = this.state.type == Globals.Session_Type.SCHEDULED_WEBINAR;

    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Online Integration</Divider>
        <Row gutter={32}>
          {!(isWebinar || isVILT) && (
            <Col span={4}>
              <Form.Item
                name="considerFailures"
                valuePropName="checked"
                label={
                  <>
                    Single Quizz Attempt
                    <Popover
                      title="Single Quizz Attempt"
                      content={CommonPopoversContent.renderConsiderFailuresPopoverContent()}
                    >
                      <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                    </Popover>
                  </>
                }
              >
                <Switch />
              </Form.Item>
            </Col>
          )}
          <Col span={7} style={{ display: 'flex' }}>
            <Form.Item
              name="onlineAccessPeriod"
              label={
                <>
                  Online Access Period
                  <Popover
                    title="Online Access Period"
                    content={CommonPopoversContent.renderOnlineAccessPeriodPopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <InputNumber min={1} />
            </Form.Item>

            <Form.Item name="onlineAccessPeriodUnity" label=" " style={{ marginLeft: 8, flex: 1 }} initialValue="days">
              <Select>
                <Select.Option value="days">Days</Select.Option>
                <Select.Option value="hours">Hours</Select.Option>
                <Select.Option value="minutes">Minutes</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          {!(isWebinar || isVILT) && (
            <Col span={7}>
              <Form.Item
                name="courseProviderID"
                label={'Course Provider'}
                rules={[{ required: true, message: 'This field is required!' }]}
              >
                <Select
                  mode="single"
                  showSearch
                  filterOption={(input, option) => {
                    const provider = (allowedOnlineCourseProviders || []).find((c) => option.value == c.id);
                    return `${provider.id} (${provider.type})`.toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  {(allowedOnlineCourseProviders || []).map((c) => {
                    return (
                      <Select.Option value={c.id} key={c.id}>
                        {c.id} ({c.type})
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}

          {!(isWebinar || isVILT) && (
            <Col span={5}>
              <Form.Item
                name="providerCourseTemplateID"
                label={'Online Course Template ID'}
                rules={[{ required: true, message: 'This field is required!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          )}

          {(isWebinar || isVILT) && (
            <Col span={17}>
              <Form.Item
                name="accessLink"
                label={'Launch URL'}
                rules={[{ required: true, message: 'This field is required!' }]}
                help="Enter the URL to be launched in a new browser tab when the user clicks the Launch button."
              >
                <Input />
              </Form.Item>
            </Col>
          )}
          <Col span={4}>
            <Form.Item
              name="calculateScoreAsPercentage"
              valuePropName="checked"
              label={
                <>
                  Calculate Score
                  <Popover
                    title="Calculate Score"
                    content={CommonPopoversContent.renderCalculatorScorePopoverContent()}
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
  _renderNotificationsSubform() {
    return (
      <div style={{ marginTop: 32 }}>
        <Divider orientation="left">Notifications</Divider>
        <Row gutter={32}>
          <Col span={10}>
            <Form.Item
              name="adminEmails"
              label={'Notification Emails'}
              rules={[{ required: false, message: 'This field is required!' }]}
              extra={
                'Enter one of more email addresses separated by comma. This emails will receive admin notifications of this session.'
              }
            >
              <Input placeholder="joedoe@example.com, joe2@example.com" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="disabledEmails" label="Disabled Emails">
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                showSearch
                filterOption={(input, option) => {
                  const provider = (Globals.Session_DisablableEmails || []).find((c) => option.value == c);
                  return provider.toLowerCase().includes(input.toLowerCase());
                }}
              >
                {Globals.Session_DisablableEmails.map((opt) => (
                  <Select.Option key={opt} value={opt}>
                    {opt}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item
              name="shouldNotifyEnrolmentToAdmins"
              label={'Send enrolment emails to admins?'}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="notifyWhenSessionFull"
              label={'Send notifications when session becomes full?'}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="disableLicenseEmails" label={'Disable license related emails?'} valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }

  /* private api */
  async _loadReferralNames() {
    if (this.state.possibleReferrals?.length <= 0) {
      this.setState({ referralOrgs: null });
      return;
    }
    let orgsResp = await this.props.app.organization.organization.searchOrganizationsByIDs({
      orgIDs: this.state.possibleReferrals,
    });
    if (orgsResp.statusCode == 200 && orgsResp.body?.orgs) {
      this.setState({ referralOrgs: orgsResp.body.orgs });
    } else this.props.app.alertController.showAPIErrorAlert(null, orgsResp);
  }
}
