import React from 'react';
import { Tooltip, Popconfirm } from 'antd';
import {
  CloudUploadOutlined,
  CloseCircleFilled,
  DownloadOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  EditOutlined,
  EyeOutlined,
  InfoCircleFilled,
  RocketOutlined,
} from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';

import '../../../../../assets/stylesheets/CommonApplicationTile.less';
import Globals from '../../../../../config/Globals';
import Utils from '../../../../../components/Utils';
import ApplicationUtils from '../../../../../components/UtilsApplication';

export default class CommonApplicationTile extends React.Component {
  render() {
    const { tile, onDelete, onEdit, skip } = this.props;
    const { status, currentProvider, currentApplicationItem, isForeignItem } = this._getTileData();

    const showDeleteAction =
      !isForeignItem &&
      [Globals.ApplicationItemsStatus.UPLOADED, Globals.ApplicationItemsStatus.REJECTED].includes(status);
    const showEditAction = [Globals.ApplicationItemsStatus.UPLOADED].includes(status);

    const acceptedProviders =
      tile?.acceptedProviders.filter((provider) => provider.type == Globals.Providers_Types.FILE) || [];
    const hasMultipleAcceptedProviders = acceptedProviders.length > 1;

    return this._tooltipWrapper(
      isForeignItem,
      'This item has already been uploaded as part of another application.'
    )(
      <div className={`application-card ${status || 'default'}`}>
        {skip && <div className="optional-badge">Optional</div>}

        {/* {(isForeignItem && status != Globals.ApplicationItemsStatus.APPROVED) && (
          <Alert
            type="info"
            message={(
              <>
                <span style={{ fontSize: 13 }}>This item has already been uploaded as part of another application.</span>
                <Popconfirm
                    title="Are you sure you don't want to use it?"
                    onConfirm={() => this.props.onDeleteForeignItem(currentApplicationItem)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="link"
                      style={{ color: '#fff', background: '#1890ff', marginTop: 8, display: 'block', fontSize: 13 }}
                      icon={<CloseCircleOutlined />}
                      size="small"
                    >
                      Don't use it
                    </Button>
                  </Popconfirm>
              </>
            )}
            style={{ marginBottom: 16 }}
          />
        )} */}

        <div className="info" style={{ height: 120 }}>
          <div className="title">
            <strong>
              <span>{tile.name}</span>
              {!currentApplicationItem?.additionalNotes && this._renderStatusIcon(status)}
              {currentApplicationItem?.additionalNotes && (
                <Tooltip
                  title={
                    <div style={{ lineHeight: 1.4 }}>
                      <small style={{ display: 'block' }}>Notes</small>
                      <span>{currentApplicationItem.additionalNotes}</span>
                    </div>
                  }
                >
                  <InfoCircleFilled />
                </Tooltip>
              )}
            </strong>

            {(showDeleteAction || showEditAction) && (
              <div className="actions">
                {showEditAction && (
                  <button type="button" onClick={() => onEdit(tile, currentProvider, currentApplicationItem)}>
                    <EditOutlined />
                  </button>
                )}
                {showDeleteAction && (
                  <Popconfirm
                    title="Are you sure to delete this item?"
                    onConfirm={() => onDelete(currentApplicationItem)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <button type="button">
                      <DeleteOutlined />
                    </button>
                  </Popconfirm>
                )}
              </div>
            )}
          </div>
          <span className="description">
            <ReactMarkdown children={tile.cardDescription} linkTarget="_blank" />
          </span>
        </div>

        <div className="card-body">
          {this._renderBody(status, currentProvider, currentApplicationItem, hasMultipleAcceptedProviders)}
        </div>
      </div>
    );
  }

  // Private method
  _tooltipWrapper(condition, message) {
    const { tooltip } = this.props;

    return (elements) => {
      if (tooltip || condition) {
        return (
          <Tooltip title={tooltip || message} placement="bottom">
            {elements}
          </Tooltip>
        );
      }

      return elements;
    };
  }

  _renderStatusIcon(status) {
    if (status === Globals.ApplicationItemsStatus.REJECTED) {
      return <CloseCircleFilled style={{ color: '#AF3947' }} />;
    }

    if (status === Globals.ApplicationItemsStatus.APPROVED || status === Globals.ApplicationItemsStatus.HISTORIC) {
      return <CheckCircleFilled style={{ color: '#39AF76' }} />;
    }

    if (status === Globals.ApplicationItemsStatus.UPLOADED) {
      return (
        <div
          style={{
            width: 22,
            height: 22,
            background: '#448EF7',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CloudUploadOutlined style={{ color: '#fff', fontSize: 14, marginLeft: 9 }} />
        </div>
      );
    }
  }

  _renderBody(status, currentProvider, currentApplicationItem, hasMultipleAcceptedProviders) {
    const { disabled, onUpload, onResubmit, onDownload, onPreview, tile } = this.props;

    const internalProviders = tile.acceptedProviders.filter(
      (provider) => provider.type == Globals.Providers_Types.INTERNAL
    );
    const hasOnlyInternalProviders = internalProviders.length == tile.acceptedProviders.length;

    const uploadLabel = hasOnlyInternalProviders
      ? 'Launch'
      : `Upload${internalProviders.length > 0 ? ' / Launch' : ''}`;

    const showDownloadAction = [
      Globals.ApplicationItemsStatus.APPROVED,
      Globals.ApplicationItemsStatus.REJECTED,
      Globals.ApplicationItemsStatus.UPLOADED,
      Globals.ApplicationItemsStatus.HISTORIC,
    ].includes(status);

    if (status === Globals.ApplicationItemsStatus.REJECTED) {
      return (
        <button
          type="button"
          className="rejected"
          disabled={disabled}
          onClick={() => onResubmit(tile, currentProvider, currentApplicationItem)}
        >
          <CloudUploadOutlined style={{ fontSize: 18 }} />
          <span>Resubmit</span>
        </button>
      );
    }

    if (status === Globals.ApplicationItemsStatus.APPROVED || status === Globals.ApplicationItemsStatus.UPLOADED) {
      return (
        <>
          <ul className="infos-list">
            {hasMultipleAcceptedProviders && (
              <li>
                <small>Provider</small>
                <strong>{this._getTrainingProviderByID(currentProvider.trainingProviderID)?.name}</strong>
              </li>
            )}
            {currentApplicationItem?.issuedOn && currentApplicationItem?.issuedOn > 0 && (
              <li>
                <small>Issued on</small>
                <strong>{Utils.getDateOnUIFormatByTimestamp(currentApplicationItem.issuedOn)}</strong>
              </li>
            )}
            {currentApplicationItem?.expiresOn && (
              <li>
                <small>Expires on</small>
                <strong>{Utils.getDateOnUIFormatByTimestamp(currentApplicationItem.expiresOn)}</strong>
              </li>
            )}
            {showDownloadAction && currentApplicationItem?.fileItems?.length > 0 && (
              <li>
                <small>Uploaded files</small>
                <ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
                  <li>
                    {currentApplicationItem.fileItems.map(({ fileID, fileName }) => (
                      <div
                        key={fileID}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: 8,
                        }}
                      >
                        <Tooltip title={fileName}>
                          <span className="card-file-name" style={{ marginLeft: 8 }}>
                            {fileName}
                          </span>
                        </Tooltip>
                        <div className="application-file-actions-buttons">
                          <button
                            className={status}
                            type="button"
                            onClick={() => onDownload(currentApplicationItem, { fileID, fileName })}
                          >
                            <DownloadOutlined />
                          </button>
                          {this._renderPreviewButton({ status, onPreview, fileName, currentApplicationItem, fileID })}
                        </div>
                      </div>
                    ))}
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </>
      );
    }

    if (status == Globals.ApplicationItemsStatus.HISTORIC) {
      return (
        <span>
          Item completed on another system at {Utils.getDateOnUIFormatByTimestamp(currentApplicationItem.timestamp)}.
        </span>
      );
    }

    return (
      <button type="button" className="upload" disabled={disabled} onClick={onUpload}>
        {hasOnlyInternalProviders ? (
          <RocketOutlined style={{ fontSize: 18 }} />
        ) : (
          <CloudUploadOutlined style={{ fontSize: 18 }} />
        )}
        <span>{uploadLabel}</span>
      </button>
    );
  }

  _renderPreviewButton({ status, onPreview, fileName, currentApplicationItem, fileID }) {
    const { extension } = Utils.splitFilenameAndExtension(fileName);
    const disabled = !['png', 'jpeg', 'jpg', 'gif', 'pdf'].includes(extension?.toLowerCase());

    return (
      <button
        className={status}
        type="button"
        onClick={() => onPreview(currentApplicationItem, { fileID, fileName }, true)}
        disabled={disabled}
        style={{ opacity: disabled ? 0.5 : 1, cursor: disabled ? 'default' : 'pointer' }}
      >
        {!disabled && <EyeOutlined />}
        {disabled && (
          <Tooltip title="Preview is not available.">
            <EyeOutlined />
          </Tooltip>
        )}
      </button>
    );
  }

  _getTrainingProviderByID(trainingProviderID) {
    const { certificationSpecs } = this.props;

    return certificationSpecs.trainingProviders?.find((trainingProvider) => trainingProvider.id == trainingProviderID);
  }

  _getTileData() {
    const { tile, applicationProcess, certificationProcess, foreignItems } = this.props;

    return ApplicationUtils.getTileData(tile, applicationProcess, certificationProcess, foreignItems);
  }
}
