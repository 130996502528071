import React from 'react';
import autoBind from 'react-autobind';
import { Select, Form, Divider, Table, Row, Typography, Col, Input, InputNumber, Popover, Switch } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import MarkdownEditor from '@ikonintegration/react-rte';
//
import Utils from '../../../components/Utils';
import Globals from '../../../config/Globals';

//props: keyValue, arrValue, label, options, relations, isElective
export default class CommonCertificationApplicationSectionForm extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      sectionDescription: MarkdownEditor.createEmptyValue(),
      title: MarkdownEditor.createEmptyValue(),
      description: MarkdownEditor.createEmptyValue(),
      isEnabled: false,
      isAlertEnabled: false,
    };
  }
  //Public
  resetFields() {
    this.setState({
      sectionDescription: MarkdownEditor.createEmptyValue(),
      title: MarkdownEditor.createEmptyValue(),
      description: MarkdownEditor.createEmptyValue(),
      isEnabled: false,
      isAlertEnabled: false,
    });
    this.form.resetFields();
  }
  setFieldsValue(data, isRenewal) {
    let ds = (isRenewal ? data?.renewal?.application : data?.application)?.[this.props.keyValue];
    if (this.props.arrValue != undefined) ds = ds?.[this.props.arrValue];
    //
    const isEnabled = ds?.sectionEnabled;
    const isAlertEnabled = ds?.alert?.description?.length > 0;
    this.setState(
      {
        isEnabled,
        isAlertEnabled,
        sectionDescription: isEnabled
          ? MarkdownEditor.createValueFromString(ds?.sectionDescription || '', 'markdown')
          : MarkdownEditor.createEmptyValue(),
        title: isAlertEnabled
          ? MarkdownEditor.createValueFromString(ds?.alert?.title || '', 'markdown')
          : MarkdownEditor.createEmptyValue(),
        description: isAlertEnabled
          ? MarkdownEditor.createValueFromString(ds?.alert?.description || '', 'markdown')
          : MarkdownEditor.createEmptyValue(),
      },
      () =>
        this.form.setFieldsValue({
          applicationSectionEnabled: isEnabled,
          alertEnabled: isAlertEnabled,
          ...(ds || {}),
          ...(ds?.alert || {}),
        })
    );
  }
  async validateFields() {
    if (!this.form) return false;
    let formData = null;
    try {
      formData = await this.form.validateFields();
    } catch (e) {}
    if (!formData) return false;
    //
    if (!this.state.isEnabled) return {};
    //
    const description = this.state.sectionDescription.toString('markdown');
    const title = this.state.title.toString('markdown');
    const obj = {
      name: formData.name,
      sectionEnabled: this.state.isEnabled,
      ...(this.props.isElective ? { requiredCount: formData.requiredCount } : {}),
      sectionDescription: description?.length > 0 ? description : null,
      ...(this.state.isAlertEnabled
        ? {
            alert: {
              title: title?.length > 0 ? title : null,
              description: this.state.description.toString('markdown'),
              type: formData.type,
            },
          }
        : {}),
    };
    //
    if (this.props.arrValue != undefined) return obj;
    return { [this.props.keyValue]: obj };
  }

  //Actions
  handleChangeEnabled() {
    this.setState({ isEnabled: !this.state.isEnabled });
  }
  handleChangeAlertEnabled() {
    this.setState({ isAlertEnabled: !this.state.isAlertEnabled });
  }
  handleEditRequirements(reqs) {
    this.requirementsModal.show(reqs, this.state.requirementsIDs.indexOf(reqs));
  }
  handleAddRequirements() {
    this.requirementsModal.show();
  }
  handleDeleteRequirements(reqs) {
    const requirementsIDs = this.state.requirementsIDs;
    requirementsIDs.splice(requirementsIDs.indexOf(reqs), 1);
    this.setState({ requirementsIDs });
  }
  handleRequirementsUpdated(reqs, index) {
    let requirementsIDs = this.state.requirementsIDs;
    if (index != -1) requirementsIDs.splice(index, 1);
    if (!requirementsIDs) requirementsIDs = [];
    if (index != -1) requirementsIDs[index] = reqs;
    requirementsIDs.push(reqs);
    this.setState({ requirementsIDs });
  }

  //UI
  render() {
    return (
      <Form layout="vertical" {...Utils.propagateRef(this, 'form')}>
        <Divider orientation="left">{this.props.label} </Divider>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item
              name="applicationSectionEnabled"
              valuePropName="checked"
              label={
                <>
                  {this.props.label} Section Enabled?
                  <Popover title="Application Enabled?" content="Indicates if the application section is enabled.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch onChange={this.handleChangeEnabled} />
            </Form.Item>
          </Col>
        </Row>
        {this.state.isEnabled && this._renderGeneralSubform()}
        {this.state.isEnabled && this._renderRelations()}
      </Form>
    );
  }
  /* Subforms */
  _renderGeneralSubform() {
    return (
      <>
        <Row gutter={32}>
          <Col span={8}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'This field is required!' }]}
              label={
                <>
                  Section Name
                  <Popover title="Name" content="Section name on the UI.">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Input />
            </Form.Item>
          </Col>
          {this.props.isElective && (
            <Col offset={1} span={8}>
              <Form.Item
                name="requiredCount"
                rules={[{ required: true, message: 'This field is required!' }]}
                label={
                  <>
                    # of required items
                    <Popover
                      title="# of required items"
                      content="The minimum number of items required for the elective section."
                    >
                      <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                    </Popover>
                  </>
                }
              >
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <Form.Item label="Section Description" name="sectionDescription" valuePropName="none">
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.sectionDescription}
                onChange={(sectionDescription) => this.setState({ sectionDescription })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={6}>
            <Form.Item
              name="alertEnabled"
              valuePropName="checked"
              label={
                <>
                  {this.props.label} Section alert enabled?
                  <Popover
                    title="Section alert enabled?"
                    content="Indicates if the application section alert is visible to users."
                  >
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Switch onChange={this.handleChangeAlertEnabled} />
            </Form.Item>
          </Col>
          <Col offset={1} span={6}>
            <Form.Item
              name="type"
              rules={[{ required: this.state.isAlertEnabled, message: 'This field is required!' }]}
              label={
                <>
                  Alert Type
                  <Popover title="Alert Type" content="Type of the alert">
                    <QuestionCircleFilled style={{ fontSize: 18, color: '#bcbcbc', marginLeft: 10 }} />
                  </Popover>
                </>
              }
            >
              <Select mode="single" showSearch style={{ width: '100%' }}>
                {Object.keys(Globals.CertificationSectionWarning_Type).map((c) => {
                  return (
                    <Select.Option value={c} key={c}>
                      {Utils.capitalizeString(c.toLowerCase())}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col span={11}>
            <Form.Item label="Alert Title" name="title" valuePropName="none">
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.title}
                onChange={(title) => this.setState({ title })}
              />
            </Form.Item>
          </Col>
          <Col span={12} offset={1}>
            <Form.Item label="Alert Description" name="description" valuePropName="none">
              <MarkdownEditor
                toolbarConfig={Utils.markdownToolbarItems()}
                value={this.state.description}
                onChange={(description) => this.setState({ description })}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  _renderRelations() {
    const columns = [
      {
        title: 'Order',
        key: 'order',
        sortOrder: 'ascend',
        sorter: (a, b) => {
          const itemA = this.props.options.find((o) => o.id == a.id);
          const itemB = this.props.options.find((o) => o.id == b.id);
          return (itemA?.order || 0) - (itemB?.order || 0);
        },
        render: (e) => {
          const item = this.props.options.find((o) => o.id == e.id);
          if (item) return item.order;
          return 'N/A';
        },
      },
      {
        title: 'Item',
        key: 'item',
        render: (e) => {
          const item = this.props.options.find((o) => o.id == e.id);
          if (item) return item.name;
          return 'Not Found!';
        },
      },
    ];
    const props = {
      rowKey: (p) => JSON.stringify(p),
      locale: { emptyText: 'No requirements found!' },
      pagination: false,
    };
    return (
      <>
        <Row gutter={32}>
          <Col span={24}>
            <Typography.Text>Items:</Typography.Text>
            <Table
              className="adminCertificationAppSectionItems"
              columns={columns}
              dataSource={[...(this.props.relations || [])]}
              {...props}
            />
          </Col>
        </Row>
      </>
    );
  }
}
